import cx from 'classnames';

import {
  SettingsGroupItem,
  useComponentColorClassName,
} from '@sb/design-system';
import type { Space } from '@sb/routine-runner';

import { PUCK_COLOR_CLASSES } from '../../puckColor';

import { SyncButton } from './SyncButton';

interface ReadonlyPositionItemProps {
  id: number;
  position: Space.Position;
  isSelected: boolean;
  onSelect: () => void;
}

export function ReadonlyPositionItem({
  id,
  position,
  isSelected,
  onSelect,
}: ReadonlyPositionItemProps) {
  const puckClassName = useComponentColorClassName(
    isSelected ? 'Default' : 'Gray',
    PUCK_COLOR_CLASSES,
  );

  return (
    <SettingsGroupItem
      isSeparated
      className={cx(isSelected && 'tw-outline-selected')}
      data-testid={`position-list-item-${id}`}
      onMouseDown={onSelect}
      onTouchStart={onSelect}
      role="button"
    >
      <div
        className={cx(
          puckClassName,
          'tw-text-15',
          'tw-min-w-[22px]',
          'tw-h-[22px]',
          'tw-rounded-full',
          'tw-flex',
          'tw-items-center',
          'tw-justify-center',
        )}
      >
        {id}
      </div>

      <span className="tw-flex-1">{position.name}</span>

      <SyncButton position={position} />
    </SettingsGroupItem>
  );
}
