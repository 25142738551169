import type { Space } from '@sb/routine-runner';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { CreateWithArm } from './CreateWithArm';
import { CreateWithCamera } from './CreateWithCamera';
import { PickCreationMethod } from './PickCreationMethod';

interface EditPlaneObject {
  spaceItem: Space.Plane;
}

export function EditPlane({ spaceItem }: EditPlaneObject) {
  const currentView = useSpaceWidgetStore((s) => s.widgetState.currentView);

  if (currentView === 'createWithCamera' || spaceItem.capturedWithCameraKind) {
    return <CreateWithCamera spaceItem={spaceItem} />;
  }

  if (currentView === 'createWithArm' || spaceItem.positions.length > 0) {
    return <CreateWithArm spaceItem={spaceItem} />;
  }

  return <PickCreationMethod />;
}
