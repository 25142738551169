import type { Robot } from '@sb/remote-control/types';
import { Card, Typography } from '@sb/ui/components';
import {
  useIsRobotPaused,
  useRobotIOState,
  useRobotStateKind,
} from '@sbrc/hooks';

import IOBadge from '../../io-manager/IOBadge';
import IOSendSignalButton from '../../io-manager/IOSendSignalButton';

import styles from './IOWidgetPortInfo.module.css';

interface IOWidgetPortInfoProps {
  io: Robot.IOPort;
  isVizbot: boolean;
}

export function IOWidgetPortInfo({ io, isVizbot }: IOWidgetPortInfoProps) {
  const ioState = useRobotIOState({
    kind: io.kind,
    port: io.port,
    isVizbot,
  });

  const routineRunnerKind = useRobotStateKind({ isVizbot });
  const isPaused = useIsRobotPaused({ isVizbot });

  const isRoutinePlaying = routineRunnerKind === 'RoutineRunning' && !isPaused;

  const isSignalButtonDisabled = io.kind === 'Output' && isRoutinePlaying;

  // current robots do not have IO label on robot document
  // alternatively can update existing bots with migration
  const label = io.kind === 'Input' ? 'IN' : 'OUT';

  const ioCardHeader = (
    <div className={styles.ioCardHeader}>
      <IOBadge className={styles.inputBadgeHeader}>
        {io.label ?? label} {io.port}
      </IOBadge>

      {io.deviceName ? (
        <>
          <Typography
            className={styles.headerIOName}
            variant="medium"
            isBold
            hasNoWrap
          >
            {io.deviceName}:
          </Typography>
          <Typography
            className={styles.headerIONameWithDevice}
            variant="medium"
            hasNoWrap
          >
            {io.name}
          </Typography>
        </>
      ) : (
        <Typography
          className={styles.headerIOName}
          variant="medium"
          isBold
          hasNoWrap
        >
          {io.name}
        </Typography>
      )}
    </div>
  );

  const canSendSignal = isVizbot
    ? io.kind === 'Input' || io.kind === 'Output'
    : io.kind === 'Output';

  return (
    <Card className={styles.IOWidgetPortInfo} borderSize="medium" hasBorder>
      {ioCardHeader}

      <div className={styles.ioManager}>
        <div className={styles.ioContainer}>
          <div className={styles.ioStatus}>
            <IOBadge
              className={styles.inputBadge}
              isCurrentIOLevel={ioState === 'high'}
            >
              H
            </IOBadge>

            <Typography variant="medium" hasNoWrap>
              {io.highSignalName}
            </Typography>
          </div>

          {canSendSignal && (
            <IOSendSignalButton
              className={styles.sendSignalButton}
              kind={io.kind}
              ioPort={io.port}
              ioLevel="high"
              disabled={ioState === 'high' || isSignalButtonDisabled}
              isVizbot={isVizbot}
            />
          )}
        </div>

        <div className={styles.ioContainer}>
          <div className={styles.ioStatus}>
            <IOBadge
              className={styles.inputBadge}
              isCurrentIOLevel={ioState === 'low'}
            >
              L
            </IOBadge>

            <Typography
              variant="medium"
              className={styles.signalText}
              hasNoWrap
            >
              {io.lowSignalName}
            </Typography>
          </div>

          {canSendSignal && (
            <IOSendSignalButton
              className={styles.sendSignalButton}
              kind={io.kind}
              ioPort={io.port}
              ioLevel="low"
              disabled={ioState === 'low' || isSignalButtonDisabled}
              isVizbot={isVizbot}
            />
          )}
        </div>
      </div>
    </Card>
  );
}
