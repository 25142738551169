import { shallow } from 'zustand/shallow';

import { MenuItem, NavigationBar, SettingsGroup } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import type { Three } from '@sb/utilities';
import { useRoutineRunnerHandle } from '@sbrc/hooks';
import { getRobotPosition } from '@sbrc/utils';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { UNSAVED_POSITION } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';
import { LabeledPositionItem } from '../position-item/LabeledPositionItem';

interface CreateWithArmProps {
  spaceItem: Space.Plane;
}

export function CreateWithArm({ spaceItem }: CreateWithArmProps) {
  const [isVizbot, widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.isVizbot, s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const positions: Three<Space.Position> = [
    spaceItem.positions[0] ?? UNSAVED_POSITION,
    spaceItem.positions[1] ?? UNSAVED_POSITION,
    spaceItem.positions[2] ?? UNSAVED_POSITION,
  ];

  const handleSavePosition = (index: number) => {
    const robotPosition = getRobotPosition(routineRunnerHandle);

    if (robotPosition) {
      const newPositions = [positions[0], positions[1], positions[2]];
      newPositions[index] = robotPosition;

      const item = {
        ...spaceItem,
        positions: newPositions,
      };

      setWidgetState({
        ...widgetState,
        item,
        updateItem: newPositions.some((p) => p === UNSAVED_POSITION)
          ? undefined
          : item,
      });
    }
  };

  const handleSwapXY = () => {
    const item = {
      ...spaceItem,
      positions: [positions[0], positions[2], positions[1]],
    };

    setWidgetState({
      ...widgetState,
      item,
      updateItem: item.positions.some((p) => p === UNSAVED_POSITION)
        ? undefined
        : item,
    });
  };

  const isSwapXYDisabled =
    positions[1] === UNSAVED_POSITION && positions[2] === UNSAVED_POSITION;

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton />}
      >
        {spaceItem.name}
      </NavigationBar>

      <p className="tw-mx-16 tw-text-15">
        To define the plane, first move the arm to its center. Then add two more
        positions to define the positive X and Y axis directions.
      </p>

      <SettingsGroup className="tw-m-16">
        <LabeledPositionItem
          label="Origin"
          position={positions[0]}
          onSave={() => handleSavePosition(0)}
        />

        <LabeledPositionItem
          label="Positive X"
          position={positions[1]}
          onSave={() => handleSavePosition(1)}
          menuItems={
            <MenuItem
              onClick={handleSwapXY}
              disabled={isSwapXYDisabled}
              iconKind="arrowSquarePath"
            >
              Swap with positive Y
            </MenuItem>
          }
        />

        <LabeledPositionItem
          label="Positive Y"
          position={positions[2]}
          onSave={() => handleSavePosition(2)}
          menuItems={
            <MenuItem
              onClick={handleSwapXY}
              disabled={isSwapXYDisabled}
              iconKind="arrowSquarePath"
            >
              Swap with positive X
            </MenuItem>
          }
        />
      </SettingsGroup>
    </WidgetView>
  );
}
