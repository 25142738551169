import { useState } from 'react';

import {
  Button,
  Icon,
  Menu,
  MenuItem,
  Popper,
  SettingsGroupItem,
} from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { areJointAnglesEqual } from '@sbrc/utils';

import { UNSAVED_POSITION } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { SyncButton } from './SyncButton';

interface LabeledPositionItemProps {
  label?: string;
  position: Space.Position;
  isSubmitting?: boolean;
  isInferred?: boolean;
  onClear?: () => void;
  onSave: () => void;
  menuItems?: React.ReactNode;
}

export function LabeledPositionItem({
  label,
  position,
  isSubmitting,
  isInferred,
  onClear,
  onSave,
  menuItems,
}: LabeledPositionItemProps) {
  const [targetJointAngles, setTargetJointAngles] = useSpaceWidgetStore(
    (s) => [s.targetJointAngles, s.setTargetJointAngles] as const,
  );

  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  const isUnsaved = position === UNSAVED_POSITION;

  const getIcon = () => {
    if (isInferred) {
      return (
        <Icon kind="checkmarkCircle" className="tw-text-label-quaternary" />
      );
    }

    if (isUnsaved) {
      return <Icon kind="exclamationTriangle" className="tw-text-mango" />;
    }

    return <Icon kind="checkmarkCircle" className="tw-text-green" />;
  };

  const getPrimaryAction = () => {
    if (isInferred) {
      return (
        <span className="tw-text-13 tw-text-label-tertiary">(Inferred)</span>
      );
    }

    if (isUnsaved) {
      return (
        <Button
          variant="Filled"
          onClick={onSave}
          disabled={isSubmitting || isInferred}
          size={32}
          className="tw-rounded-6 tw-mx-auto"
        >
          Set
        </Button>
      );
    }

    return <SyncButton position={position} className="tw-mx-auto" />;
  };

  return (
    <SettingsGroupItem>
      {getIcon()}

      {label && (
        <span className="tw-flex-initial tw-min-w-[78px]">{label}</span>
      )}

      {getPrimaryAction()}

      {isUnsaved ? (
        <Icon kind="blank" />
      ) : (
        <Popper
          isOpen={isMoreMenuOpen}
          onClose={() => setIsMoreMenuOpen(false)}
          content={
            <Menu>
              <MenuItem
                onClick={onSave}
                disabled={isSubmitting}
                iconKind="plusCircle"
              >
                Replace position
              </MenuItem>
              {onClear && (
                <MenuItem
                  onClick={onClear}
                  iconKind="trash"
                  className="tw-text-red"
                >
                  Clear position
                </MenuItem>
              )}
              <MenuItem
                onClick={() => setTargetJointAngles(position.jointAngles)}
                disabled={areJointAnglesEqual(
                  position.jointAngles,
                  targetJointAngles,
                )}
                iconKind="joint"
              >
                Show saved joint angles
              </MenuItem>
              {menuItems}
            </Menu>
          }
          placement="bottom-end"
          skidding={13}
          onClick={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
        >
          <Icon
            kind="ellipsisCircle"
            className="tw-text-label-secondary tw-mr-[--tw-icon-terminal-margin]"
          />
        </Popper>
      )}
    </SettingsGroupItem>
  );
}
