import { useThree } from '@react-three/fiber';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import type { Mesh } from 'three';
import { BufferGeometry, DoubleSide, Vector2 } from 'three';

import {
  cameraPoseFromWristPose,
  deprojectOntoPlane,
  type Plane,
} from '@sb/geometry';
import type { CameraIntrinsics } from '@sb/integrations/camera/types';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

interface VisualizeCameraFOVProps {
  plane: Plane;
}

export function VisualizeCameraFOV({ plane }: VisualizeCameraFOVProps) {
  const isVizbot = useSpaceWidgetStore((s) => s.isVizbot);
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const [intrinsics, setIntrinsics] = useState<CameraIntrinsics>();

  const meshRef = useRef<Mesh>(null);

  const invalidate = useThree((state) => state.invalidate);

  useEffect(() => {
    const run = async () => {
      const result = await routineRunnerHandle.runVisionMethod({
        method: 'getIntrinsics',
      });

      if (result.method === 'getIntrinsics') {
        setIntrinsics(result.results);
      }
    };

    run();
  }, [routineRunnerHandle]);

  useEffect(() => {
    const unsubscribe = routineRunnerHandle.onStateChange(
      (s) => s.kinematicState.wristPose,
      isEqual,
      (wristPose) => {
        const mesh = meshRef.current;

        if (!intrinsics || !mesh) {
          return;
        }

        const cameraPose = cameraPoseFromWristPose(wristPose);

        const a = deprojectOntoPlane({
          pixelCoordinates: new Vector2(0, 0),
          plane,
          cameraPose,
          cameraIntrinsics: intrinsics,
        });

        const b = deprojectOntoPlane({
          pixelCoordinates: new Vector2(intrinsics.width, 0),
          plane,
          cameraPose,
          cameraIntrinsics: intrinsics,
        });

        const c = deprojectOntoPlane({
          pixelCoordinates: new Vector2(intrinsics.width, intrinsics.height),
          plane,
          cameraPose,
          cameraIntrinsics: intrinsics,
        });

        const d = deprojectOntoPlane({
          pixelCoordinates: new Vector2(0, intrinsics.height),
          plane,
          cameraPose,
          cameraIntrinsics: intrinsics,
        });

        if (a && b && c && d) {
          mesh.geometry = new BufferGeometry()
            // triange1 = abd / triangle2 = dbc
            .setFromPoints([a, b, d, d, b, c]);
        } else {
          mesh.geometry = new BufferGeometry();
        }

        invalidate();
      },
    );

    return unsubscribe;
  }, [plane, intrinsics, routineRunnerHandle, invalidate]);

  return (
    <mesh ref={meshRef}>
      <meshBasicMaterial
        color="#222"
        side={DoubleSide}
        transparent
        opacity={0.1}
        depthWrite={false}
      />
    </mesh>
  );
}
