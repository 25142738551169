import type { Space } from '@sb/routine-runner';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { VisualizePlaneSurface } from './VisualizePlaneSurface';

interface VisualizePlaneProps {
  spaceItem: Space.Plane;
}

export function VisualizePlane({ spaceItem }: VisualizePlaneProps) {
  const isEditing = useSpaceWidgetStore(
    (s) => s.widgetState.item?.id === spaceItem.id,
  );

  if (isEditing) {
    return <VisualizePlaneSurface spaceItem={spaceItem} showAxes />;
  }

  return null;
}
