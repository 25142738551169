import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import type { Space } from '@sb/routine-runner';
import { six, wait } from '@sb/utilities';
import { getLocateMethodArgsFromLocateStepArgs } from '@sbrc/components/step-wizard/locate-step/getVisionMethodArgumentsFromLocateStepArguments';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

type ResultStatus = 'init' | 'loading' | 'loaded' | 'error';

export function useVisionPositionList(spaceItem: Space.VisionPositionList) {
  const [isVizbot, stepConfiguration, setWidgetState, space] =
    useSpaceWidgetStore(
      (s) =>
        [
          s.isVizbot,
          s.routine?.stepConfigurations[spaceItem.id],
          s.setWidgetState,
          s.routine?.space,
        ] as const,
      shallow,
    );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const [status, setStatus] = useState<ResultStatus>('init');

  const getResults = async () => {
    if (
      stepConfiguration?.stepKind === 'Locate' &&
      stepConfiguration.args &&
      stepConfiguration.args.planeID
    ) {
      const { planeID } = stepConfiguration.args;

      const plane = space?.find(
        (s): s is Space.Plane => s.id === planeID && s.kind === 'plane',
      );

      if (!plane || plane.positions.length < 3) {
        setStatus('error');

        return;
      }

      setStatus('loading');

      try {
        const visionMethodArgs = getLocateMethodArgsFromLocateStepArgs(
          stepConfiguration.args,
          plane,
        );

        const [result] = await Promise.all([
          routineRunnerHandle.runVisionMethod(visionMethodArgs),
          // minimum duration to avoid flashing of 'loading' state
          wait(500),
        ]);

        if (
          result.method === 'locate2DBlobs' ||
          result.method === 'locate2DShapes'
        ) {
          setWidgetState({
            item: {
              ...spaceItem,
              positions: result.results.map(({ pose, blob }) => ({
                jointAngles: six(0),
                pose,
                name: `Score: ${blob.score}%`,
              })),
            },
          });

          setStatus('loaded');

          return;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    setStatus('error');
  };

  useEffect(() => {
    if (status === 'init') {
      getResults();
    }
  });

  return {
    status,
    getResults,
  };
}
