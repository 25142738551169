import type { Robot } from '@sb/remote-control/types';
import { TabItem, Tabs } from '@sb/ui/components';

import { IOWidgetPortInfo } from './IOWidgetPortInfo';
import { getSafetyPorts } from './utils';

import styles from './IOWidgetTabs.module.css';

interface VisualizerViewIOProps {
  isVizbot: boolean;
  robot: Robot.ConvertedResponse;
}

export function IOWidgetTabs({ isVizbot, robot }: VisualizerViewIOProps) {
  return (
    <Tabs tabListClassName="tw-sticky tw-top-0 tw-surface-secondary">
      <TabItem
        label="Inputs"
        id="Input"
        panel={
          <div className={styles.inputOutputList}>
            {robot.ioInputs.map((ioInput) => (
              <IOWidgetPortInfo
                key={ioInput.port}
                io={ioInput}
                isVizbot={isVizbot}
              />
            ))}
          </div>
        }
      />

      <TabItem
        label="Outputs"
        id="Output"
        panel={
          <div className={styles.inputOutputList}>
            {robot.ioOutputs.map((ioOutput) => (
              <IOWidgetPortInfo
                key={ioOutput.port}
                io={ioOutput}
                isVizbot={isVizbot}
              />
            ))}
          </div>
        }
      />

      <TabItem
        label="Safety"
        id="safety"
        panel={
          <div className={styles.inputOutputList}>
            {getSafetyPorts().map((safetyIO) => (
              <IOWidgetPortInfo
                key={safetyIO.port}
                io={safetyIO}
                isVizbot={isVizbot}
              />
            ))}
          </div>
        }
      />
    </Tabs>
  );
}
