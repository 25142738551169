import { useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  NavigationBar,
  SettingsGroup,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import {
  ZERO_ROTATION,
  applyCompoundPose,
  cameraPoseFromWristPose,
  constructPlaneFromPose,
  convertVector3ToPosition,
  tooltipToBaseTransform,
  matrix4ToCartesianPose,
} from '@sb/geometry';
import type { CartesianPose } from '@sb/geometry';
import type { Space } from '@sb/routine-runner';
import { six } from '@sb/utilities';
import { SelectCamera } from '@sbrc/components/camera';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { CreateWithCameraModal } from './CreateWithCameraModal';

interface CreateWithCameraProps {
  spaceItem: Space.Plane;
}

const getPlanePose = (
  cameraToChessboardPose: CartesianPose,
  cameraPose: CartesianPose,
): CartesianPose => {
  const planePose = applyCompoundPose(cameraToChessboardPose, cameraPose);

  // Rotate the result to account for the camera's "base rotation"
  const rotationMat = tooltipToBaseTransform().invert();

  return applyCompoundPose(matrix4ToCartesianPose(rotationMat), planePose);
};

export function CreateWithCamera({ spaceItem }: CreateWithCameraProps) {
  const routineRunnerHandle = useRoutineRunnerHandle({});

  const [widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <WidgetView>
        <NavigationBar
          className="tw-pl-8 tw-pr-8"
          contentLeft={<HeaderBackButton />}
        >
          {spaceItem.name}
        </NavigationBar>

        <p className="tw-mx-16 tw-text-15 tw-text-label-secondary">
          This plane can be used in vision steps like Locate to help the robot
          find what it sees with the camera
        </p>

        <SettingsGroup className="tw-m-16">
          <SelectCamera value="wristCamera" onChange={() => {}} />
          <SettingsGroupNavigationItem
            label="Capture"
            onClick={() => setIsModalOpen(true)}
          />
        </SettingsGroup>
      </WidgetView>

      <CreateWithCameraModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCapture={(camera2chessboard) => {
          const wristPose =
            routineRunnerHandle.getState()?.kinematicState.wristPose;

          if (!wristPose) {
            return;
          }

          const cameraPose = cameraPoseFromWristPose(wristPose);

          const planePose = getPlanePose(camera2chessboard, cameraPose);

          const { origin, plusX, plusY } = constructPlaneFromPose(planePose);

          const positions = [origin, plusX, plusY].map((vec) => {
            const asPose: CartesianPose = {
              ...ZERO_ROTATION,
              ...convertVector3ToPosition(vec),
            };

            return {
              pose: asPose,
              jointAngles: six(0),
            };
          });

          const item = {
            ...spaceItem,
            positions,
            capturedWithCameraKind: 'wristCamera',
          };

          setWidgetState({
            ...widgetState,
            item,
            updateItem: item,
          });
        }}
      />
    </>
  );
}
