import { cartesianPositionFromPose, type CartesianPose } from '@sb/geometry';
import type { LocateStep } from '@sb/remote-control/types';
import type {
  RunDetect2DBlobsArgs,
  RunDetect2DShapesArgs,
  RunLocate2DBlobArgs,
  RunLocate2DShapeArgs,
  Space,
} from '@sb/routine-runner';

export function getDetectionMethodArgsFromLocateStepArgs(
  args: LocateStep.Arguments,
): RunDetect2DBlobsArgs | RunDetect2DShapesArgs {
  const { method } = args;

  switch (method.kind) {
    case 'BlobDetection2D': {
      return {
        method: 'detect2DBlobs',
        camera: args.camera,
        regionOfInterest: args.regionOfInterest,
        params: method.settings,
      };
    }
    case 'ShapeDetection2D': {
      return {
        method: 'detect2DShapes',
        camera: args.camera,
        regionOfInterest: args.regionOfInterest,
        params: method.settings,
        templateImage: method.templateImage,
      };
    }
    default: {
      // @ts-expect-error
      throw new Error(`Unexpected method kind: ${method.kind}`);
    }
  }
}

export function getLocateMethodArgsFromLocateStepArgs(
  args: LocateStep.Arguments,
  plane: Space.Plane,
): RunLocate2DBlobArgs | RunLocate2DShapeArgs {
  const { method } = args;

  const planePoints = [
    cartesianPositionFromPose(plane.positions[0].pose),
    cartesianPositionFromPose(plane.positions[1].pose),
    cartesianPositionFromPose(plane.positions[2].pose),
  ] as [CartesianPose, CartesianPose, CartesianPose];

  switch (method.kind) {
    case 'BlobDetection2D': {
      return {
        method: 'locate2DBlobs',
        params: method.settings,
        camera: args.camera,
        regionOfInterest: args.regionOfInterest,
        plane: planePoints,
        resultsLimit: args.resultsLimit,
      };
    }
    case 'ShapeDetection2D': {
      return {
        method: 'locate2DShapes',
        params: method.settings,
        camera: args.camera,
        regionOfInterest: args.regionOfInterest,
        plane: planePoints,
        resultsLimit: args.resultsLimit,
        templateImage: method.templateImage,
      };
    }
    default: {
      // @ts-expect-error
      throw new Error(`Unexpected method kind: ${method.kind}`);
    }
  }
}
