import { create } from 'zustand';

import type { ArmJointPositions } from '@sb/motion-planning';
import type { Routine } from '@sb/remote-control/types';

import type { WidgetState } from './types';

interface SpaceWidgetStoreSync {
  robotID: string;
  isVizbot: boolean;
  routine: Routine.ConvertedResponse | null;
  targetJointAngles: ArmJointPositions | null;
  setTargetJointAngles: (jointAngles: ArmJointPositions | null) => void;
}

interface SpaceWidgetStore extends SpaceWidgetStoreSync {
  widgetState: WidgetState;
  setWidgetState: (newState: WidgetState) => void;
  setWidgetCurrentView: (view: WidgetState['currentView']) => void;
  sync: (args: SpaceWidgetStoreSync) => void;
}

export const useSpaceWidgetStore = create<SpaceWidgetStore>((set, get) => {
  return {
    robotID: '',
    isVizbot: true,
    routine: null,
    targetJointAngles: null,
    setTargetJointAngles: () => {},
    widgetState: {},
    setWidgetState: (widgetState) => set({ widgetState }),
    setWidgetCurrentView: (currentView) =>
      set({ widgetState: { ...get().widgetState, currentView } }),
    sync: (args) => set(args),
  };
});
