import type { Space } from '@sb/routine-runner';
import { six } from '@sb/utilities';

export interface WidgetState {
  item?: Space.Item;
  updateItem?: Space.Item;
  removeItemID?: string;
  renameItem?: Space.Item;
  createItemKind?: Space.ItemKind;
  currentPositionIndex?: number;
  currentView?: 'gridSetup' | 'createWithArm' | 'createWithCamera';
}

export const UNSAVED_POSITION: Space.Position = {
  jointAngles: six(NaN),
  pose: { i: NaN, j: NaN, k: NaN, w: NaN, x: NaN, y: NaN, z: NaN },
  name: 'UNSAVED',
};
