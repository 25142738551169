import { useEffect, useState } from 'react';

import { Modal, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import * as log from '@sb/log';
import type { Point2D } from '@sb/routine-runner';
import { wait } from '@sb/utilities';
import { CameraStream, DEFAULT_WRIST_CAMERA } from '@sbrc/components/camera';
import { ChessboardOverlay } from '@sbrc/components/camera/stream/ChessboardOverlay';
import { useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import { CreateWithCameraMessageOverlay } from './CreateWithCameraMessageOverlay';

const CHESSBOARD_DEFN = {
  cols: 7,
  rows: 5,
  // actually specified in meters, despite the name
  squareSizeMM: 0.03,
};

interface CreateWithCameraModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCapture: (transform: CartesianPose) => void;
}

export function CreateWithCameraModal({
  isOpen,
  onClose,
  onCapture,
}: CreateWithCameraModalProps) {
  const routineRunnerHandle = useRoutineRunnerHandle({});
  const { setToast } = useToast();

  const [chessboardCorners, setChessboardCorners] = useState<Point2D[] | null>(
    null,
  );

  useEffect(() => {
    if (!isOpen) {
      setChessboardCorners(null);

      return undefined;
    }

    let cancelled = false;

    const getChessboardCorners = async () => {
      while (!cancelled) {
        try {
          const cornersResult = await routineRunnerHandle.runVisionMethod({
            method: 'getChessboardCorners',
            camera: DEFAULT_WRIST_CAMERA,
            ...CHESSBOARD_DEFN,
          });

          if (cornersResult.method === 'getChessboardCorners') {
            setChessboardCorners(cornersResult.results);
          }
        } catch (error) {
          log.error(
            'CreateWithCameraModal.getChessboardCorners.error',
            'Get chessboard corners failed',
            { error },
          );

          setChessboardCorners(null);
        }

        await wait(1000);
      }
    };

    getChessboardCorners();

    return () => {
      cancelled = true;
    };
  }, [routineRunnerHandle, isOpen]);

  const handleCapture = async () => {
    try {
      const transformResult = await routineRunnerHandle.runVisionMethod({
        method: 'getCameraChessboardTransform',
        camera: DEFAULT_WRIST_CAMERA,
        ...CHESSBOARD_DEFN,
      });

      if (transformResult.method === 'getCameraChessboardTransform') {
        onCapture(transformResult.results);
        onClose();
      }
    } catch (error) {
      log.error(
        'CreateWithCameraModal.handleCapture.error',
        'getCameraChessboardTransform failed',
        { error },
      );

      setToast({
        kind: 'error',
        message: 'Failed to capture calibration board.',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      /* don't close on click outside */
      onClose={() => {}}
      className="tw-w-[90vw] tw-h-[80vh] tw-dark"
    >
      <NavigationBar
        className="tw-flex-none"
        contentLeft={
          <NavigationBarButton onClick={onClose}>Cancel</NavigationBarButton>
        }
        contentRight={
          <NavigationBarButton
            onClick={handleCapture}
            disabled={!chessboardCorners || chessboardCorners.length === 0}
          >
            Capture
          </NavigationBarButton>
        }
      >
        Create plane
      </NavigationBar>
      <div className="tw-flex-1 tw-h-0 tw-m-24 tw-mt-8 tw-flex tw-items-center tw-justify-center">
        <CameraStream>
          {chessboardCorners && (
            <>
              <ChessboardOverlay points={chessboardCorners} />
              <CreateWithCameraMessageOverlay
                isFound={chessboardCorners.length > 0}
              />
            </>
          )}
        </CameraStream>
      </div>
    </Modal>
  );
}
