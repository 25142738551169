import { shallow } from 'zustand/shallow';

import {
  Button,
  Icon,
  NavigationBar,
  SettingsGroupItem,
} from '@sb/design-system';
import type { Space } from '@sb/routine-runner';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';
import { ReadonlyPositionItem } from '../position-item/ReadonlyPositionItem';

import { useVisionPositionList } from './useVisionPositionList';

interface EditVisionPositionListProps {
  spaceItem: Space.VisionPositionList;
}

export function EditVisionPositionList({
  spaceItem,
}: EditVisionPositionListProps) {
  const [widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  const handleSelect = (index: number) => () => {
    setWidgetState({ ...widgetState, currentPositionIndex: index });
  };

  const { currentPositionIndex = 0 } = widgetState;

  const { status, getResults } = useVisionPositionList(spaceItem);

  const isLoading = status === 'loading' || status === 'init';

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton />}
      >
        {spaceItem.name}
      </NavigationBar>

      <div className="tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-px-16 tw-pb-16">
        <Button
          variant="Filled"
          className="tw-rounded-6"
          disabled={isLoading}
          onClick={getResults}
        >
          <Icon kind="arrowCirclePath" />
          <span>{isLoading ? 'Loading positions' : 'Refresh positions'}</span>
        </Button>

        {status === 'error' && (
          <p className="tw-mt-8 tw-text-red tw-text-13">
            Unable to run locate.
          </p>
        )}

        <h5 className="tw-heading-40 tw-pl-16 tw-mt-16">
          <span>Positions</span>
          <span className="tw-text-label-tertiary tw-font-regular">
            ({spaceItem.positions.length})
          </span>
        </h5>

        <div className="tw-flex tw-flex-col tw-gap-8">
          {spaceItem.positions.length === 0 && (
            <SettingsGroupItem isSeparated>
              <Icon kind="circleSlash" />
              <span>No positions</span>
            </SettingsGroupItem>
          )}

          {spaceItem.positions.map((position, index) => {
            const positionID = `${index}`;
            const isSelected = index === currentPositionIndex;

            return (
              <ReadonlyPositionItem
                key={positionID}
                id={index}
                position={position}
                isSelected={isSelected}
                onSelect={handleSelect(index)}
              />
            );
          })}
        </div>
      </div>
    </WidgetView>
  );
}
