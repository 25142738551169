import { Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';

import WidgetView from '../widget-panel/WidgetView';

import { IOWidgetTabs } from './IOWidgetTabs';

interface IOWidgetProps {
  isVizbot: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

export function IOWidget({ isVizbot, onClose, robot }: IOWidgetProps) {
  return (
    <WidgetView
      onClose={onClose}
      headerTitle="I/O"
      headerIcon={<Icon kind="powerplug" />}
    >
      <IOWidgetTabs isVizbot={isVizbot} robot={robot} />
    </WidgetView>
  );
}
